import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const MarketableSecuritiesPage = ({ data }) => {
    const { html, frontmatter } = data.file.childMarkdownRemark;
    return (
        <Layout>
            <SEO title="Marketable Securities"/>
            <section className="hero is-light is-medium is-banner marketable-securities">
              <div className="background-image"/>
            </section>
            <section className="section">
                <div className="container">
                  <h1 className="title">{frontmatter.headline}</h1>
                  <div className="content" dangerouslySetInnerHTML={{ __html: html }}/>
                </div>
            </section>
        </Layout>
    );
}

export const query = graphql`
  query MarketableSecuritiesQuery {
    file(name: {eq: "marketable-securities"}) {
      childMarkdownRemark {
        frontmatter {
          headline
        }
        html
      }
    }
  }
`

export default MarketableSecuritiesPage